


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";

import {
    PlannerAvailableResources,
    PlannerDemandResources,
    PlannerDemandResourcesResponse
} from "@/interfaces";
import * as types from "@/store/types";

@Component({
    components: {
        SvgPanZoom: () => import("vue-svg-pan-zoom/src/SvgPanZoom.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Scheme extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    annualResources = false;

    availableResources: PlannerAvailableResources = {
        superficial: 0,
        subterranea: 0,
        reutilizada: 0,
        desalada: 0,
        trasvase: 0
    };

    demandResources: PlannerDemandResources = {
        AMBIENTAL: 0,
        UDA: 0,
        UDI: 0,
        UDU: 0,
        UDRG: 0
    };

    mounted() {
        this.fetchDemandResources();
    }

    async fetchDemandResources() {
        try {
            this.setProgressBar(true);
            const demandResources = await this.$api.getDemandResources<
                PlannerDemandResourcesResponse
            >();

            if (demandResources && demandResources.ok) {
                this.availableResources =
                    demandResources.data.availableResources;
                this.demandResources = demandResources.data.demandResources;
                this.annualResources = true;
            }
        } catch (error) {
            if (error instanceof Error) {
                if (error.message === "Network Error") {
                    this.showError(String(this.$t("netError")));
                } else {
                    this.showError(error.message);
                }
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    /**
     * Show error message
     * @param {string} error Error message
     * @return {void}
     */
    showError(error: string): void {
        this.setInfoMessage({ shown: true, text: error });
    }
}
